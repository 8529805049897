/**
 * This is early and only done to capture all kommun and lanskod
 *
 * use it as `<spb-kommun-selector (kommunSelected)="setKommun($event)"></spb-kommun-selector>`
 *
 * This should possibly be changed a lot once we have form to use it in.
 */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {FormControl} from '@angular/forms'
import {map, startWith} from 'rxjs/operators'
import {Observable} from 'rxjs'
import data from './kommunlankod27.json'

/**
 * Simple interface to the kommun-object
 */
export interface Kommun {
  /**
   * Länskod
   */
  lanKod: string

  /**
   * Kommunkod
   */
  kommunKod: string

  /**
   * Name of the kommun, e.g. Lomma
   */
  kommunNamn: string

  /**
   * The name of the Län, e.g. Skåne Län.
   */
  lanNamn: string
}

@Component({
  selector: 'spb-kommun-selector',
  templateUrl: './kommun-selector.component.html',
  styleUrls: ['./kommun-selector.component.scss']
})
export class KommunSelectorComponent implements OnInit {

  /**
   * A form control to use with the autocomplete
   */
  public myControl = new FormControl<string>('', {nonNullable: true})

  /**
   * Emits events when there is one item in list. i.e. the selected
   * option. Passes the whole kommun object to the listener.
   */
  @Output() kommunSelected = new EventEmitter()

  /**
   * The list of kommun after filtering.
   */
  public filteredKommun: Observable<Array<Kommun>>
  /**
   * We need to separate the input from the @Input() so
   * that we can set it again. Same is true for the kommunkod below.
   */
  private pCountyCode: string
  /**
   * This is kommunkod.
   */
  private pMunicipalityCode: string
  /**
   * A very long list of all kommun in Sweden, like 290 of them.
   */
  private kommunList = data as Array<Kommun>

  /**
   * Länskod, we need to use a setter to be able to
   * catch updates. Otherwise it will only set once.
   */
  @Input()
  set countyCode(code: string) {
    this.pCountyCode = code
  }

  /**
   * Kommunkod, we need to use a setter to be able to
   * catch updates. Otherwise, it will only set once.
   */
  @Input()
  set municipalityCode(code: string) {
    this.pMunicipalityCode = code
  }

  /**
   * Sets the "value change" for the formControl so that
   * every time the value changes it filters the list.
   * If only one item in list the event emitter is triggered
   */
  public ngOnInit(): void {
    this.setKommun()
    this.filteredKommun = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value)),
      map(list => {
        if (list.length === 1) {
          this.kommunSelected.emit(list[0])
        }
        return list
      })
    )
  }

  /**
   * Called when community or municipality code needs to be translated
   * into a kommun name.
   */
  private setKommun(): void {
    const community = this.kommunList.find(komun => {
      return komun.kommunKod === this.pMunicipalityCode && komun.lanKod === this.pCountyCode
    })
    if (community) {
      this.myControl.setValue(community.kommunNamn)
    }
  }

  /**
   * Filters the list based on the input value, if the value
   * @param value - A string that is entered.
   */
  private filter(value: string): Array<Kommun> {
    const filterValue = value.toLowerCase()
    return this.kommunList.filter(kommun => {
      return kommun.kommunNamn.toLowerCase().indexOf(filterValue) !== -1
    })
  }
}
