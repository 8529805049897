import {Injectable} from '@angular/core'

export enum LoanType {
  NONE = -1,
  HOUSING = 0,
  BLANCO,
  CARD_CREDIT
}

export enum OccupationType {
  PERMANENT = 0, // Quite some business logic depends on 0 and 1 so do not move these to around!
  RETIRED = 1,
  TIME_LIMITED,
  HOURLY,
  OWN_COMPANY,
  STUDENT,
  UNEMPLOYED
}

export enum PropertyType {
  NONE = -1,
  VILLA = 0,
  FRITIDSHUS,
  BOSTADSRATT
}


export enum UcDecision {
  APPROVED = 'J',
  DECLINED = 'N'
}

/**
 * A user as used in the admin parts.
 */
export interface IUser {
  /**
   * Basically personnummer. Should at least be masked
   */
  id: string

  /**
   * First and last name like "Jonas Jonasson"
   */
  name: string

  /**
   * An array of strings like ["admin", "developer"]
   */
  roles: string[]
}

/**
 * What is needed for mocking UC.
 */
export interface UCInfo {

  /**
   * The applicant full name like 'Claus 'Daniel' Lennert Bergdahl
   */
  applicantName: string

  /**
   * Länkod e.g. "01"
   */
  countyCode: string

  /**
   * Kommunkod e.g. "18"
   */
  municipalityCode: string

  /**
   * Församlingskod, not really used at the moment. E.g. "01"
   */
  parishCode?: string

  /**
   * Yearly income and array of strings with year income before tax
   * e.g. ["123000", "234000"] the first is last year, next the year before that.
   */
  income: Array<string>

  /**
   * Decision is "J" or "N", where J is approved and N is not.
   */
  decision: UcDecision

  /**
   * Reasons is an array of reasons as strings
   */
  reasons: Array<string>

  /**
   * Status is normally "complete" but can change in the future
   */
  status: string
}

/**
 * Applicant contact information
 */
export interface ApplicantContact {
  /**
   * First and last name preferrably e.g. Nikita Chrusttjoff
   */
  name?: string
  /**
   * Email
   */
  email: string

  /**
   * Phone number
   */
  phone: string
}

/**
 * Applicant data
 */
export interface Applicant {
  /**
   * Income before tax in SEK, is initially a string like "25 000" then converted
   * to a number 25000
   */
  income: string | number

  /**
   * The personnummer in free form
   */
  personNummer: string

  /**
   * The partner/sambo/make/maka in free form
   */
  spouse?: string

  /**
   * The employer of the applicant
   */
  employer?: string

  /**
   * True if employer is an a-kassa member
   */
  akassa?: boolean

  /**
   * Study loan cost per month, string "2 000" or number 2000
   * This has to be kept here until November 2020 to stay backwards
   * compliant to old model see issue loan-frontend#58
   */
  studyLoan?: string | number

  /**
   * Occupation 0 is employed, 1 retired
   */
  occupation: OccupationType

  /**
   * A convenience field for entering human readable occupation
   */
  occupationName?: string

  /**
   * Living expense, normally 9500 for the first and 7500 for second
   */
  livingExpense?: number

  /**
   * Contact information
   */
  contact: ApplicantContact

  /**
   * UC Data if mocked
   */
  uc?: UCInfo

  /**
   * Calculated tax data
   */
  tax?: ApplicantTax

  /**
   * The return from tax. This has poor naming in backend.
   */
  taxReduction?: ApplicantTaxReturn

  /**
   * Display name is a calculated name with that is pretty to display.
   */
  displayName?: string
}

export interface ApplicantTax {

  /**
   * The amount of tax to pay SEK/Mon
   */
  tax?: number

  /**
   * The column
   */
  taxColumn?: number | string

  /**
   * The value in percent that you pay in your kommun
   */
  taxValue: number | string

  /**
   * If you pay a percentage this value is set.
   */
  taxPercent?: number | string
}

export interface ApplicantTaxReturn {

  /**
   * How much is payed in interest in SEK per annum.
   */
  interest: number

  /**
   * The total in SEK you get back in interest per annum.
   */
  interestTaxReturn: number

  /**
   * For returns over 100K? you only get 21% back. The amount in SEK
   */
  lowReturn: number
}

/**
 * New Property is the property we are seeking loans for.
 */
export interface NewProperty {

  /**
   * What the expected price of the property is
   */
  price: string | number

  /**
   * How much to pay in cash (or other loans actually)
   */
  downPayment: string | number

  /**
   * The down payment in percent. This is currently
   * calculated by the front end
   */
  downPaymentPercent?: number

  /**
   * The amount to loan, basically price - downPayment
   */
  loanAmount?: number

  /**
   * Villa, bostadsrätt or fritidshus.
   */
  type: PropertyType

  /**
   * Fee in case of type = Bostadsrätt.
   */
  fee?: string | number

  /**
   * Fastighetsavgift ~ 8250 / 12 Monthly in SEK.
   */
  propertyTax?: number

  /**
   * Cost, fixed per type of property, SEK / Month.
   */
  runCost?: number

  /**
   * The amount of interest to pay in percent.
   */
  interestPercent?: number

  /**
   * The monthly interest to pay per month in SEK
   */
  interestSumMonth?: number

  /**
   * The mortgage, per month in SEK.
   */
  mortgage?: number

  /**
   * The percentage to mortgage per year.
   */
  mortgagePercent?: number

  /**
   * Convenience item to display the name rather thant the type (0,1,2)
   */
  typeName?: string
}

export interface AdditionalLoan {
  /**
   * The total amount.
   */
  amount: string | number

  /**
   * The type of extra loan, House, Blanco, Credit ..
   */
  type: LoanType

  /**
   * The interest as in 0.01 Varies between types of loan.
   */
  interestPercent?: number

  /**
   * The interest to pay in SEK / Month
   */
  interest?: number

  /**
   * Mortgage percent as in 0.03, depens on loan type. For house loans
   * We expect it to vary by the debt quota.
   */
  mortgagePercent?: number

  /**
   * Mortgage on loan in SEK / Month.
   */
  mortgage?: number

  /**
   * Blanco, house etc.
   */
  loanTypeName?: string
}

/**
 *
 */
export interface OldProperty {

  type: PropertyType

  /**
   * Convenience item to display the name rather thant the type (0,1,2)
   */
  typeName?: string

  runCost?: number

  tax?: number

  fee?: string | number
}

/**
 * Total counted tax reduction for household
 */
export interface TaxReduction {
  totalInterestMonth: number
  totalInterestYear: number
  taxDeductionMonth: number
  taxDeductionYear: number
  taxDeduction21Month: number
  taxDeduction21Year: number
}

export interface ILoanPromiseAdmin {
  /**
   * Who is assigned to this. Should be a name "Daniel Bergdahl"
   */
  assignee?: string
}


/**
 * This is the complete loan promise
 */
export interface LoanPromise {
  /**
   * The current verison, update this to reset old versions.
   */
  version: number

  /** The applicants */
  applicants: Applicant[]

  /**
   * Helper to know if we have co-applicant clicked.
   */
  coApplicant: boolean

  /**
   * The house or apartment to buy
   */
  property: NewProperty

  /**
   * Number of children in house hold
   */
  childrenCount: number

  /**
   * The cost of having children ~ n * 3500
   */
  childrenCost?: number

  /**
   * The total children benefit (barnbidrag) per month ~ n * 1250
   */
  childrenBenefit?: number

  /**
   * Extra for many children per month (flerbarnstillägg), SEK / month
   */
  childrenBenefitExtra?: number

  /**
   * Helper to detect if we have additional loans
   */
  additionalLoans?: boolean

  /**
   * Studyloan is calcualted per house hold. SEK/Month.
   */
  studyLoan?: string | number

  /**
   * Additional loans.
   */
  loans?: Array<AdditionalLoan>

  /**
   * Existing house to keep. Maximum one as it seems.
   */
  oldProperty?: OldProperty

  /**
   * A summary of tax reductions for the house hold.
   */
  taxReduction?: TaxReduction

  /**
   * The kalp as in +/- SEK / Month.
   */
  kalp?: number

  /**
   * The KALP given we do not add this new loan.
   */
  rawKalp?: number

  /**
   * A guessed value you can loan for approximately in case of positive raw kalp.
   */
  maxPropertyLoan?: number

  /**
   * A timestamp to detect if we have an old application stored
   * in local storage
   */
  timeStamp?: number

  /**
   * Indicates if terms and conditions are checked or not.
   */
  terms?: boolean

  /**
   * Indicates if terms and conditions are checked or not for manual application.
   */
  termsManual?: boolean

  /**
   * Other info, a free text field for user to enter some information
   */
  otherInfo?: string

  /**
   * Admin data if present
   */
  adminData?: ILoanPromiseAdmin

  /**
   * Cars, used in admin template
   */
  cars?: {
    monthlyCarCost: number,
    numOfCars: number,
    monthlyPrivateLeaseCarCost: number,
    individualCars: { carType: string, monthlyCost: number }[]
  }
}

export interface LoanPromiseListItem {
  'loan-promise-id': string
  status: string // approved/rejected,
  dateOfIssue: number // ms
  kalp: number //
  applicantId: string // Personnummer 12 digits 199506241711
  approvedAmount: number // Eg.. 2125000,
  applicantName: string // Full name from UC e.g.   "Carl 'Johann' Fredric Lindberg" for applicant
  coApplicantName: string // Same as applicantName for co-applicant.
  applicantPersonNummer: string // The personnummer of the applicant
  coApplicantPersonNummer: string // The Personnummer of the co-applicant.
  above75: number // Amount requested > 75% of purchase price,
  contactEmail?: string // The email to contact
  contactPhone?: string // The phoneNumber to contact.
  adminData: ILoanPromiseAdmin,
  statusReason?: string,
  seekedAmount?: number,
  superfast?: boolean,
}


/**
 * Item to use in lists of property types.
 */
export interface PropertyTypeListItem {
  name: string
  type: PropertyType
}

/**
 * Different types of loan, Blanco, Credit etc.
 */
export interface LoanTypeListItem {
  /**
   * Like Blanco etc.
   */
  name: string

  /**
   * Technical type - needs to be in sync with backend.
   */
  type: LoanType
}

export interface OccupationTypeListItem {
  /**
   * Like Tillsvidare, Arbeitslos etc.
   */
  name: string

  /**
   * Enum 0, 1, ... 6
   */
  value: OccupationType
}

@Injectable()
export class GenericTypesService {
  /**
   * Types of additional loans
   */
  public static LoanTypes: Array<LoanTypeListItem> = [
    {
      name: 'Bostad',
      type: LoanType.HOUSING
    },
    {
      name: 'Blanco',
      type: LoanType.BLANCO
    },
    {
      name: 'Kortkredit',
      type: LoanType.CARD_CREDIT
    }
  ]

  public static PropertyTypes: Array<PropertyTypeListItem> = [
    {
      name: 'Villa',
      type: PropertyType.VILLA
    },
    {
      name: 'Fritidshus',
      type: PropertyType.FRITIDSHUS
    },
    {
      name: 'Bostadsrätt',
      type: PropertyType.BOSTADSRATT
    }
  ]

  /**
   * A list to use for displaying property types
   */
  public static OccupationTypes: OccupationTypeListItem[] = [
    {
      name: 'Tillsvidare',
      value: OccupationType.PERMANENT
    },
    {
      name: 'Visstid',
      value: OccupationType.TIME_LIMITED
    },
    {
      name: 'Timanställd',
      value: OccupationType.HOURLY
    },
    {
      name: 'Egen företagare',
      value: OccupationType.OWN_COMPANY
    },
    {
      name: 'Student',
      value: OccupationType.STUDENT
    },
    {
      name: 'Pensionär',
      value: OccupationType.RETIRED
    },
    {
      name: 'Arbetssökande',
      value: OccupationType.UNEMPLOYED
    }
  ]

  public static TestSubjects = [
    {id: '760818-5992', text: 'Personen har aktuellt skuldsaldo hos Kronofogdemyndigheten (ASK och ASM)'},
    {id: '700327-8293', text: 'Personen har anmält förlorad id-handling till UC (IDH och IDM)'},
    {
      id: '971219-7178',
      text: 'Personen har betalningsanmärkning, av ' +
        'typen allmänna och enskilda mål, de senaste 12 månaderna. (ANM och AMM)'
    },
    {id: '750624-1764', text: 'Box-adress i storstadsregionerna'},
    {id: '730526-8836', text: 'Stockholm, Göteborg eller Malmö (BOX och BOM)'},
    {id: '680219-0527', text: 'Förvaltare utsedd (FRV och FRM)'},
    {id: '820712-2063', text: 'Notering om skuldsanering (SKS och SSM)'},
    {id: '360125-3960', text: 'Poste Restante-adress (POS och POM)'},
    {id: '700525-5158', text: 'Skyddade personuppgifter (SKY och SKM)'},
    {id: '760421-4796', text: 'Saknar adress i UC:s register (ADR och ADM)'},
    {id: '010910-6179', text: 'Under 18 år (UNG och UNM)'},
    {id: '810127-0356', text: 'Avliden (AVL och AVM)'},
    {id: '670705-1832', text: 'Riskprognos (0,045)'},
    {id: '650904-9745', text: 'Riskprognos (0,049)'},
    {id: '810505-1315', text: 'Riskprognos (0,115)'},
    {id: '420407-8309', text: 'Senast taxerade inkomsten(116000)'},
    {id: '460304-4282', text: 'Senast taxerade inkomsten (107000)'},
    {id: '781005-2816', text: 'Saldo utnyttjad kredit Blancolån (347975)'},
    {id: '670307-7971', text: 'Saldo utnyttjad kredit Blancolån(348015)'},
    {id: '650819-9608', text: 'Nekad risk 4.2%'},
    {id: '931111-6892', text: '931111 Nekad (2) ANM och AMM: Anmärkningar äldre än 36 månader'},
    {id: '931111-3170', text: '931111 Nekad (2) ANM och AMM: Anmärkningar äldre än 36 månader'},
    {id: '920408-4736', text: '920408 Nekad (2) ANM och AMM: Anmärkningar äldre än 36 månader'},
    {id: '990416-1057', text: '990416 Nekad (2) TAX och TAM: Inkomst under 180 000 kr '},
    {id: '981204-7075', text: '981204 Nekad (2) TAX och TAM: Inkomst under 180 000 kr '},
    {id: '950624-1711', text: '950624 Beviljning'},
    {id: '621028-6701', text: '621028 Beviljning'},
    {id: '881118-3238', text: '881118 Beviljning'},
    {id: '760610-5307', text: '760610 Beviljning (2)'},
    {id: '690515-1368', text: '690515 Beviljning (2)'},
    {id: '690509-1325', text: '690509 Beviljning (2)'},
    {id: '690310-4401', text: '690310 Beviljning (2)'}
  ]

}
