// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // loanServiceUrl: 'https://gulapuckar-www-daniel.nytthem.sparbankensyd.se/api',
  loanServiceUrl: 'https://gulapuckar-www-jorgen.nytthem.sparbankensyd.se/api',
  hemnetServiceUrl: 'https://hemnet-api-test.nytthem.sparbankensyd.se/api',
  commonServiceUrl: 'https://spb-common-api-test.internal.sparbankensyd.se/service',
  showDebug: true,
  domain: 'gulapuckar-www-jorgen.nytthem.sparbankensyd.se'
}
